@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  position: relative;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 1;
  background-color: #242424;
}

.bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.bg img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

body::-webkit-scrollbar {
  width: 0;
}

.App_wrapper__2Rh_f {
  height: 100vh;
  padding: 20px;
  display: flex;
  position: relative;
  justify-content: space-between;
}

.App_search_info__juNgZ {
  background-color: #263130;
  color: #495958;
  padding: 10px 30px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 10;
  opacity: 0.9;
}

.App_search_bar__1-EwE {
  display: flex;
  justify-content: center;
}

.App_search_bar__1-EwE input {
  font-size: 25px;
  padding: 10px 5px;
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid #495958;
  color: #dcdede !important;
  margin-right: 20px;
  transition: 0.5s;
}

.App_search_bar__1-EwE button {
  outline: none;
  background-color: #829f9a;
  border: none;
  cursor: pointer;
  font-weight: bold;
  padding: 25px;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App_static_cities__2E36s {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #495958;
}

.App_static_cities__2E36s button {
  color: #495958;
  transition: 0.5s;
  cursor: pointer;
  background: transparent;
  font-size: 19px;
  border: none;
  margin: 20px 0;
}

.App_static_cities__2E36s button:hover {
  color: #dcdede;
}

.App_weather_info_box__b8TMo {
  padding-top: 20px;
}

.App_weather_info_box__b8TMo h2 {
  font-size: 22px;
  color: #dcdede;
  font-weight: 400;
}

.App_weather_info__1vlSm {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  font-size: 20px;
}

.App_weather_info__1vlSm span:nth-child(2) {
  color: #dcdede;
}

.App_content__3La4L {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #263130;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  padding: 10px 20px;
  color: #dcdede;
  opacity: 0.9;
  width: 50%;
}

.App_temp_box__2O4AN {
  font-size: 80px;
  flex: 1 1;
}

.App_city_info__1fTCX {
  text-align: center;
  flex: 2 1;
}

.App_city_info__1fTCX h1 {
  font-size: 40px;
}

.App_weather_icon__2m0WR {
  display: flex;
  align-items: center;
  flex: 1 1;
}

.App_error__1umzI {
  text-align: center;
  font-size: 20px;
  color: #dcdede;
  padding: 20px 0;
  width: 100%;
}

.App_weather_img__lP9Wq {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

