body::-webkit-scrollbar {
  width: 0;
}

.wrapper {
  height: 100vh;
  padding: 20px;
  display: flex;
  position: relative;
  justify-content: space-between;
}

.search_info {
  background-color: #263130;
  color: #495958;
  padding: 10px 30px;
  height: fit-content;
  z-index: 10;
  opacity: 0.9;
}

.search_bar {
  display: flex;
  justify-content: center;
}

.search_bar input {
  font-size: 25px;
  padding: 10px 5px;
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid #495958;
  color: #dcdede !important;
  margin-right: 20px;
  transition: 0.5s;
}

.search_bar button {
  outline: none;
  background-color: #829f9a;
  border: none;
  cursor: pointer;
  font-weight: bold;
  padding: 25px;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.static_cities {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #495958;
}

.static_cities button {
  color: #495958;
  transition: 0.5s;
  cursor: pointer;
  background: transparent;
  font-size: 19px;
  border: none;
  margin: 20px 0;
}

.static_cities button:hover {
  color: #dcdede;
}

.weather_info_box {
  padding-top: 20px;
}

.weather_info_box h2 {
  font-size: 22px;
  color: #dcdede;
  font-weight: 400;
}

.weather_info {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  font-size: 20px;
}

.weather_info span:nth-child(2) {
  color: #dcdede;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #263130;
  height: fit-content;

  padding: 10px 20px;
  color: #dcdede;
  opacity: 0.9;
  width: 50%;
}

.temp_box {
  font-size: 80px;
  flex: 1;
}

.city_info {
  text-align: center;
  flex: 2;
}

.city_info h1 {
  font-size: 40px;
}

.weather_icon {
  display: flex;
  align-items: center;
  flex: 1;
}

.error {
  text-align: center;
  font-size: 20px;
  color: #dcdede;
  padding: 20px 0;
  width: 100%;
}

.weather_img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
